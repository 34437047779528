<template>
	<header id="site-header" class="top-0 z-20 w-full text-white" :class="isTop ? 'absolute bg-siteBlue':'fixed shadow-md'">
		<div class="" :class="isTop ? 'py-4 lg:py-6':'py-2 bg-siteBlue duration-200'">
			<div class="container">
				<div class="flex flex-wrap items-center justify-center sm:justify-between sm:flex-nowrap gap-y-4 lg:gap-y-8">

					<router-link to="/" class="items-center w-full gap-x-4 sm:w-auto" :class="isTop ? '':'hidden lg:flex'">
						<img class="w-auto mx-auto" :class="isTop ? 'h-16 lg:h-auto':'lg:h-10'" src="@/assets/images/logo.png" alt="Aspen Valves &amp; Fittings Logo" width="314" height="78">
						<!-- <div class="text-2xl italic font-bold leading-none">
							Aspen Valves<br>
							& Fittings
						</div> -->
					</router-link>

					<div class="flex items-center">
						<div class="flex" :class="isTop ? 'gap-x-8':''">

							<div class="items-start hidden lg:gap-x-3" :class="isTop ? 'lg:flex':''">
								<img class="w-auto h-8 lg:h-auto" src="@/assets/images/map-icon.png" alt="map icon" width="70" height="70">
								<div class="text-sm">
									<p class="font-bold font-rubik">ADDRESS</p>
									<p class="leading-tight">
										<!-- 826 Reina Regente St.,<br> -->
										Binondo, Manila
									</p>
								</div>
							</div>

							<div class="flex items-center gap-x-2 lg:gap-x-3" :class="isTop ? 'lg:items-start':''">
								<img class="w-auto h-8 lg:h-auto" src="@/assets/images/tel-icon.png" :class="isTop ? '':'lg:h-10'" alt="contact icon" width="70" height="70">
								<div class="text-base font-bold leading-tight lg:text-lg font-rubik">
									<p class="hidden text-sm" :class="isTop ? 'lg:block':''">CALL US NOW</p>
									<a href="tel:+639176787168" class="hover:underline" :class="isTop ? 'lg:block lg:leading-tight':''">
										<span class="inline-block">0917 678 7168</span>
									</a>
									<span class="" :class="isTop ? 'lg:hidden':''">&nbsp; / &nbsp;</span>
									<a href="tel:+639176755168" class="hover:underline" :class="isTop ? 'lg:block lg:leading-tight':''">
										<span class="inline-block">0917 675 5168</span>
									</a>
									<span class="hidden" :class="isTop ? '':'lg:inline'">&nbsp; / &nbsp;</span>
									<a href="tel:+63282444451" class="hidden hover:underline" :class="isTop ? 'lg:block lg:leading-tight':'lg:inline'">
										<span class="inline-block">(02) 8244 4451</span>
									</a>
								</div>
							</div>

						</div>
					</div>

				</div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	data () {
		return {
			isTop: true,
		};
	},
	mounted () {
		document.addEventListener('scroll', this.handleScroll, {passive: true});
	},
	methods: {
		handleScroll() {
			this.isTop = window.scrollY < 200;
			// if (window.pageYOffset >= 200) {
			// 	if (this.isTop) this.isTop = false;
			// } else if ( document.documentElement.scrollTop < 200 ) {
			// 	if (!this.isTop) this.isTop = true;
			// }
		},
	},
}
</script>

<style scoped>

</style>
